<!-- <aside class="wishlist-section border-end"> -->
<div class="whishlist-search border-bottom d-flex w100 align-items-center">
  <i class="bi bi-search text-mute ms-2"></i>
  <div style="width: 100% !important; position: relative">
    <input
      type="text"
      [formControl]="searchControl"
      #searchStock
      appUppercaseWord
      class="form-control form-control-sm border-0 bg-transparent"
      placeholder="Search (TSLA,NVDA,AAPL,etc)"
    />
    @if(resultSets?.length&& isStockListDisplay){
    <div class="suggestion-box">
      <ul class="suggestion-list">
        @for (suggestion of resultSets; track suggestion?.id) {
        <li>
          <div class="d-flex justify-content-between align-items-center">
            <div
              [innerHTML]="
                suggestion?.name + ' (' + suggestion?.ticker + ')'
                  | highlightWord : searchControl.value : 'igm'
              "
            ></div>

            <a
              [ngbTooltip]="suggestion?.toShow ? 'Remove Stock' : 'Add Stock'"
              class="cursor-pointer ms-2"
              (click)="addStockToWatchList(suggestion, $event)"
            >
              <i
                class="bi fs-5"
                [ngClass]="[
                  suggestion?.toShow
                    ? 'bi-dash-circle-fill text-light'
                    : 'bi-plus-circle-fill text-primary'
                ]"
              ></i>
            </a>
          </div>
        </li>
        <hr />
        }
      </ul>
    </div>
    } @if(!resultSets?.length && isStockListDisplay) {
    <div class="suggestion-box">
      <ul class="suggestion-list">
        <li>No Stock Found</li>
      </ul>
    </div>
    }
  </div>
  <small class="text-mute"
    ><span>{{ watchlistData.length }}</span
    >/<span>50</span></small
  >
  <a class="p-2 h5 mb-0"><i class="bi bi-funnel-fill"></i></a>
</div>

<div
  class="stock-list-row"
  drag-scroll-item
>
  @if(isLoading){
  <div class="text-center p-4">
    <ngx-skeleton-loader
      [theme]="{
        'border-radius': '4px',
        height: '25px',
        width: '100%'
      }"
      [count]="tableOptions.limit"
    >
    </ngx-skeleton-loader>
  </div>
  } @if (!isLoading && (!watchList.length || !watchlistData.length)) {

  <div class="text-center w100 h100 tab-content text-center">
    <i class="bi bi-binoculars fs-1 text-light"></i>
    <h3 class="text-light">Nothing Here</h3>
    @if (!watchList.length) {
    <button
      class="btn btn-md btn-secondary mt-3"
      (click)="watchListAction('Create')"
    >
      Create WatchList
    </button>
    }
  </div>
  }
  <div [class]="watchlistData.length > 20 ? 'overflow-y-auto overflow-x-hidden':''"  [ngbNavOutlet]="nav"></div>
  <div
    class="w100"
    style="background-color: #f6f9fd"
  >
    <div class="row d-flex align-items-center me-0">
      <div class="col-10 col-sm-11">
        <ul
          ngbNav
          #nav="ngbNav"
          class="nav nav-pills justify-content-start mb-0 mt-auto stock-list-scroll"
          [(activeId)]="activeId"
          (navChange)="onNavChange($event)"
        >
          @for (watchListitem of watchList; track i;let i= $index) {
          <li [ngbNavItem]="i + 1">
            <div
              ngbDropdown
              container="body"
            >
              <a
                ngbNavLink
                class="nav-link"
              >
                {{ watchListitem?.name }}
                <span
                  class="dropdown-toggle-split"
                  class="cursor-pointer"
                  [ngbTooltip]="'Actions'"
                  ngbDropdownToggle
                  ><i class="bi bi-three-dots-vertical"></i>
                </span>
              </a>
              <div
                ngbDropdownMenu
                class="dropdown-minwidth"
              >
                <button
                  class="dropdown-item w-100"
                  (click)="watchListAction('Edit', watchListitem?.name)"
                >
                  <i class="bi bi-pencil-square fs-6"></i>Rename
                </button>
                <button
                  class="dropdown-item w-100"
                  (click)="openDeleteModal(watchListitem?.name)"
                >
                  <i class="bi bi-trash fs-6"></i>Delete
                </button>
              </div>
            </div>

            <ng-template ngbNavContent>
              @defer () { @if(watchlistData && watchlistData.length>0 &&
              !isLoading){
              <app-custom-data-table
                [showHeader]="true"
                [displayedColumnArray]="displayedColumnArray"
                [data]="watchlistData"
                [tableOptions]="tableOptions"
                [isComponentSideAction]="componentAction"
                [tableClass]="'table-stock-perfomance'"
              ></app-custom-data-table>
              <ng-template
                #componentAction
                let-fieldName="fieldName"
                let-item="item"
              >
                @switch (fieldName) { @case ('ticker') {
                <td>
                  <strong>{{ item?.ticker }}</strong>
                </td>
                } @case ('todaysChange') {
                <td class="text-end">
                  @if(isSocketLive && (item?.prevDay?.c!==0 &&
                  item?.lastTrade?.p!==0 )){
                  <span
                    [ngClass]="
                      item?.prevDay?.c
                        | priceDifference
                          : item?.lastTrade?.p
                          : 'change'
                          : 'class'
                    "
                  >
                    {{
                      (item?.lastTrade?.p-item?.prevDay?.c  )| number : '1.2-2'
                    }}
                  </span>
                  } @else {
                  <span
                    [ngClass]="
                      item?.todaysChange | priceChanges : 'class' : 'change'
                    "
                  >
                    {{ item?.todaysChange | number : '1.2-2' }}
                  </span>
                  }
                </td>
                } @case ('todaysChangePerc') {
                <td class="text-end">
                  @if(isSocketLive && (item?.prevDay?.c!==0 &&
                  item?.lastTrade?.p!==0 )){
                  <span
                    [ngClass]="
                      item?.prevDay?.c
                        | priceDifference
                          : item?.lastTrade?.p
                          : 'percentage'
                          : 'class'
                    "
                  >
                    {{
                     ( ((item?.lastTrade?.p-item?.prevDay?.c) /
                        item?.prevDay?.c) *
                        100) | number : '1.2-2'
                    }}%
                  </span>
                  <span>
                    <i
                      style="font-size: 11px"
                      [ngClass]="
                        item?.prevDay?.c
                          | priceDifference
                            : item?.lastTrade?.p
                            : 'percentage'
                            : 'icon'
                      "
                    >
                    </i>
                  </span>
                  } @else {
                  <span
                    [ngClass]="
                      item?.todaysChangePerc
                        | priceChanges : 'class' : 'percentage'
                    "
                  >
                    {{ item?.todaysChangePerc | number : '1.2-2' }}%
                  </span>
                  <span>
                    <i
                      style="font-size: 11px"
                      [ngClass]="
                        item?.todaysChangePerc
                          | priceChanges : 'icon' : 'percentage'
                      "
                    >
                    </i>
                  </span>
                  }
                </td>
                } @case ('price') {
                <td class="text-end text-danger">
                  <strong
                    [ngClass]="item?.lastTrade?.p | priceChanges : 'class'"
                    >{{ (item?.lastTrade?.p || item?.prevDay?.c) | number : '1.2-2' }}</strong
                  >
                  <div class="action-section">
                    <a
                      (click)="stockBuySell(item?.ticker, 'buy')"
                      [ngbTooltip]="'Buy'"
                      class="btn btn-sm btn-success fw-bold"
                      >B</a
                    >
                    <a
                      [ngbTooltip]="'Sell'"
                      class="btn btn-sm btn-danger fw-bold"
                      >S</a
                    >
                    <a
                      [ngbTooltip]="'Remove'"
                      (click)="onRemoveStock(item?.ticker)"
                      class="btn btn-sm btn-outline btn-light"
                      ><i
                        class="bi bi-trash text-dark"
                        aria-hidden="true"
                      ></i
                    ></a>
                    <a
                      [routerLink]="['/stock-details', item?.ticker]"
                      class="btn btn-sm btn-outline btn-light"
                      [ngbTooltip]="'Information'"
                      ><i class="bi bi-text-left text-dark"></i
                    ></a>
                    <div
                      ngbDropdown
                      placement="bottom-end"
                      #dropdown="ngbDropdown"
                    >
                      <button
                        ngbDropdownToggle
                        class="btn btn-sm btn-outline-light"
                      >
                        <i
                          class="bi bi-three-dots-vertical text-dark"
                          aria-hidden="true"
                        ></i>
                      </button>
                      <div
                        ngbDropdownMenu
                        class="dropdown-menu animated fadeIn"
                      >
                        <button ngbDropdownItem>Technical</button>
                        <button ngbDropdownItem>Set Alert</button>
                        <button ngbDropdownItem>Market Depth</button>
                      </div>
                    </div>
                  </div>
                </td>
                } }
              </ng-template>
              } }
            </ng-template>
          </li>
          }
        </ul>
      </div>
      <div class="col-2 col-sm-1 h100">
        <a
          [ngbTooltip]="'Create WatchList'"
          container="body"
          (click)="watchListAction('Create', '')"
          class="cursor-pointer"
        >
          <i
            class="bi bi-plus-circle"
            style="font-size: 19px"
          ></i>
        </a>
      </div>
    </div>
  </div>
</div>

<app-common-modal
  [isModalOpen]="isCreateModalOpen"
  [actionType]="{actionType,actionValue:watchListName}"
  [type]="'WatchList'"
  (submitCreateModal)="actionOnWatchList($event)"
></app-common-modal>
<!-- </aside> -->
